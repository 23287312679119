/* ==========================================================================
   MIXINS
   ========================================================================== */

%tab-focus {
  // Default
  outline: thin dotted $warning-color;
  // Webkit
  outline: 5px auto $warning-color;
  outline-offset: -2px;
}

/*
   em function
   ========================================================================== */

@function em($target, $context: $doc-font-size) {
  @return ($target / $context) * 1em;
}


/*
   Bourbon clearfix
   ========================================================================== */

// Provides an easy way to include a clearfix for containing floats.
//
// @link http://cssmojo.com/latest_new_clearfix_so_far/
//
// @example scss - Usage
//   .element {
//     @include clearfix;
//   }
//
// @example css - CSS Output
//   .element::after {
//     clear: both;
//     content: "";
//     display: table;
//   }

@mixin clearfix {
  clear: both;

  &::after {
    clear: both;
    content: "";
    display: table;
  }
}