/* ==========================================================================
   MASTHEAD
   ========================================================================== */

.masthead {
  position: relative;
  border-bottom: 1px solid $border-color;
  animation: intro 0.3s both;
  animation-delay: 0.15s;
  z-index: 20;

  &__inner-wrap {
    @include container;
    @include clearfix;
    padding: 1em 1em 1em;
    font-family: $sans-serif-narrow;

    @include breakpoint($x-large) {
      max-width: $x-large;
    }

    nav {
      z-index: 10;
    }

    a {
      text-decoration: none;
    }
  }
}

.masthead__menu {

  ul {
    margin: 0;
    padding: 0;
    clear: both;
    list-style-type: none;
  }
}

.masthead__menu-item {
  display: block;
  list-style-type: none;
  white-space: nowrap;

  &--lg {
    padding-right: 2em;
    font-weight: 700;
  }
}