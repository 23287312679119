/* ==========================================================================
   PRINT STYLES
   ========================================================================== */

/*
   Hide the following elements on print
   ========================================================================== */

@media print {
  .masthead,
  .toc,
  .page__share,
  .page__related,
  .ads,
  .page__footer {
    display: none;
  }
}
